//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getShoppingCartSID } from '@/utils/helpers'
import {mapActions} from "vuex";
export default {
  name: "login",
  data() {
    return {
      login: {
        username: '',
        password: '',
      },
      rules: {
        required: (value) => !!value || 'This is a required field.',
      },
    }
  },
  methods:{
    ...mapActions('common/cache', ['loadingCache']),
    resetData(){
      this.login = {
        username: '', 
        password: '',
      }
    },
    async signIn(){
      if(this.$refs.loginForm.validate()){
        try {
          const response = await this.$auth.loginWith('local', {
            data: {
              username: this.login.username,
              password: this.login.password,
              sessionId: getShoppingCartSID(),
            },
          })
          if (response.status === 200 && response.data.token) {
            this.$toast.success('Logged in!')
            this.resetData()
            this.$emit('close')
            await this.loadingCache()
          }else if(response.success === false){
            this.$toast.error("Invalid login request!");
          }
        }catch (e){
          this.$toast.error("Invalid login request!");
          console.log('err: ', e)
        }
      }
    },
  },
}
