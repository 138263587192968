//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {emailRules} from '@/utils/validationRules'
import {mapState, mapActions} from 'vuex'
import {recaptchaMixin} from '@/mixins/recaptchaMixin'

export default {
  name: 'RegistrationDialogLayout',
  mixins: [recaptchaMixin],
  data() {
    return {
      emailRules,
      valid: false,
      contactInfoValid: false,
      createContactPerson: false,
      contactInfoForm: {
        firstName: '',
        lastName: '',
        phone: '',
      },
      registrationForm: {
        email: '',
        storeName: '',
        address: '',
        city: '',
        countryID: '',
        stateID: '',
        website: '',
        comment: '',
      },
      selectedCountry: undefined,
      selectedState: undefined,
    }
  },
  computed: {
    ...mapState('common/crm/territorialUnit', ['allStates', 'countries']),
    states() {
      if (this.selectedCountry) {
        return this.$store.state.common.crm.territorialUnit.allStates.filter(
          (s) => s.parentId === this.selectedCountry.value
        )
      }
      return []
    },
  },
  async mounted() {
    if (!this.allStates || this.allStates.length <= 0)
      await this.fetchAllStates()
    if (!this.countries || this.countries.length <= 0)
      await this.fetchCountries()
  },
  methods: {
    ...mapActions('common/crm/territorialUnit', [
      'fetchCountries',
      'fetchAllStates',
    ]),
    async register() {
      if (this.$refs.registrationForm.validate()) {
        if (this.createContactPerson) {
          if (!this.$refs.contactInfoForm.validate()) {
            return
          }
        }

        const googleRecaptchaResult = await this.checkIfUserIsHuman('register')
        if (
          !googleRecaptchaResult.success ||
          googleRecaptchaResult.score < 0.5
        ) {
          return
        }

        const form = {
          Email: this.registrationForm.email,
          Company: this.registrationForm.storeName,
          BillingAddress: this.registrationForm.address,
          BillingCity: this.registrationForm.city,
          Website: this.registrationForm.website,
          Notes: this.registrationForm.comment,
          ShippingSameAsBilling: true,
          CreateUser: false,
        }

        if (this.selectedState) {
          form.BillingStateID = this.selectedState.value
        }
        if (this.selectedCountry) {
          form.BillingCountryID = this.selectedCountry.value
        }

        if (this.createContactPerson) {
          form.FirstName = this.contactInfoForm.firstName
          form.LastName = this.contactInfoForm.lastName
          form.Phone = this.contactInfoForm.phone
        }
        const result = await this.$axios.$post('Auth/register', form)
        if (result && result.hasErrors === false) {
          this.$toast.success(
            'Your request has been submitted and will be reviewed by CLIQ shortly.'
          )
          this.$emit('close')
        } else {
          this.$toast.error(result.errorMessages.error)
        }
      }
    },
    onCountryChanged(v) {
      this.selectedState = undefined
      if (typeof v === 'string' || v instanceof String) {
        this.selectedCountry = this.countries.find((x) => x.text === v)
      }
    },
    onStateChanged(v) {
      if (typeof v === 'string' || v instanceof String) {
        this.selectedState = this.states.find((x) => x.text === v)
      }
    },
  },
}
