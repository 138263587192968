//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegistrationDialogLayout from '@/frontend/components/common/registration-dialog-layout.vue'
import ForgotPasswordLayout from '@/frontend/components/common/forgot-password-layout.vue'
import Login from '@/frontend/components/common/login.vue'

export default {
  name: 'LoginOperationsDialog',
  components: {
    Login,
    RegistrationDialogLayout,
    ForgotPasswordLayout,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bottomLinks: [
        {title: 'Log In', link: 'login'},
        {title: 'Register', link: 'registration'},
        {title: 'Lost your password?', link: 'lost-password'},
      ],
    }
  },
  computed: {
    currentBottomLinks() {
      return this.bottomLinks.filter((link) => link.link !== this.mode)
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    changeMode(to) {
      if (to) {
        // eslint-disable-next-line vue/no-mutating-props
        this.mode = to
      }
    },
  },
}
