//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginOperationsDialog from '@/frontend/components/common/login-operations-dialog.vue'

export default {
  name: 'Footer',
  components: {
    LoginOperationsDialog,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      content: {},
      footerMenus: [],
      loginOperationsDialog: false,
      currentMode: undefined,
    }
  },
  async mounted() {
    this.content = (await this.$axios.get(`/contents/footer`)).data

    const vueObj = {}
    Object.assign(vueObj, this.content.details)
    this.footerMenus = vueObj.sections[0].footer_menus
    
    if(this.$route.query && this.$route.query.dialog) {
      if(!this.$auth.loggedIn) {
        switch (this.$route.query.dialog) {
          case this.dialogTypes.register:
            this.openDialog('registration')
                break;
          case this.dialogTypes.login:
            this.openDialog('login')
                break;
          case this.dialogTypes.lostPassword:
            this.openDialog('lost-password')
                break;
        }
      }
    }
  },
  methods: {
    openDialog(mode) {
      this.currentMode = mode
      this.loginOperationsDialog = true
    },
    closeDialog() {
      this.loginOperationsDialog = false
    },
  },
  computed: {
    dialogTypes() {
      return {
        login: 'login',
        register: 'register',
        lostPassword: 'lost-password'
      }
    }
  },
}
