export const recaptchaMixin = {
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  },
  methods: {
    async checkIfUserIsHuman(actionType) {
      let googleResult = ''
      try {
        const token = await this.$recaptcha.execute(actionType)
        await this.$axios
          .$get(`/recaptcha-handler`, {
            params: {
              googleToken: token,
            },
          })
          .then((res) => {
            googleResult = JSON.parse(res)
          })
      } catch (e) {
        console.log(e)
      }
      return googleResult
    },
  },
}
