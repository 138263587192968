//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {emailRules} from '@/utils/validationRules'

export default {
  name: 'ForgotPasswordLayout',
  data() {
    return {
      emailRules,
      forgotPasswordForm: {
        email: '',
      },
    }
  },
  methods: {
    async requestPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        const result = await this.$axios.$post(
          'Auth/forgotPassword',
          this.forgotPasswordForm
        )

        if (result && result.apiMessages.hasErrors === false) {
          const [message] = result.apiMessages.successMessages
          this.$toast.success(message)
          this.forgotPasswordForm.email = ''
          this.$refs.forgotPasswordForm.resetValidation()
          this.$emit('close')
        } else {
          const [error] = result.apiMessages.serverErrors
          this.$toast.error(error)
        }
      }
    },
  },
}
