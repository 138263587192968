export const emailRules = [
    v => !!v || 'E-mail is required',
    v => (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid'
]
export const componentNameRules = [
  v => !!v || 'Name is required',
  v => (v && /^[a-z][a-z0-9_-]+$/.test(v)) || "Name must be lowercase, start with a letter and can only contain letters, numbers, '-', '_'"
]
export const componentFieldNameRules = [
  v => !!v || 'Name is required',
  v => (v && /^[a-z][a-z0-9_]+$/.test(v)) || "Name must be lowercase, start with a letter and can only contain letters, numbers, '_'"
]
export const requiredRules = [
   value => !!value || 'This field is required'
]

